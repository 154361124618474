import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import logo from "../assets/logo_prototype_2.png";
import "./Navbar.css";
import "primeicons/primeicons.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [visible, setVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchClick = () => {
    // Run the search
    if (searchTerm) {
      //alert(`Searching matches for "${searchTerm}"`);
      navigate(`/bills?term=${searchTerm}`);
    } else {
      alert(`No search term provided`);
    }
    return;
  };

  return (
    <>
      <div>
        <div className="h-[5em] bg-votur-indigo-500 flex items-center justify-between">
          <div className="mx-2 flex items-center">
            <Button
              icon="pi pi-bars"
              size="large"
              onClick={() => setVisible(true)}
            ></Button>
            <div>
              <a href="/">
                <img src={logo} alt="Votur.net logo"></img>
              </a>
            </div>
          </div>

          {/* Search Box */}
          <span className="p-input-icon-right">
            <i
              className="pi pi-search"
              onClick={() => {
                handleSearchClick();
              }}
              style={{ cursor: "pointer" }}
            />
            <InputText
              placeholder="Search"
              onKeyUp={(keyEvent) => {
                if (keyEvent.code === "Enter") {
                  handleSearchClick();
                }
              }}
              onChange={(e) => setSearchTerm(e ? e.target.value : "")}
            />
          </span>

          <div className="mr-3 border-2 border-solid border-black h-16 w-16 justify-self-center"></div>
        </div>

        {/* Sidebar */}
        <div>
          <Sidebar
            className="mt-[10em]"
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <h2>Sidebar</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Sidebar>
        </div>
      </div>
    </>
  );
}

export default Navbar;
