import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bill } from "../shared/Bill";
import BillService from "../services/BillService";
import LegislatorService from "../services/LegislatorService";
import { Legislator } from "../shared/Legislator";
import { ScrollArea, Grid } from "@mantine/core";
import { Skeleton } from "@mantine/core";

function BillProfile() {
  const { id } = useParams();
  const [bill, setBill] = useState<Bill>();
  const [legislator, setLegislator] = useState<Legislator>();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [imgLoading, setImgLoading] = useState(false);
  const [billText, setBillText] = useState<string>();

  // Get the bill object
  useEffect(() => {
    if (id) {
      BillService.getBill(id).then((bill) => setBill(bill));
      BillService.getBillText(id).then((billText) => setBillText(billText));
    }
  }, [id]);

  // Get the legislator object
  useEffect(() => {
    setImgLoading(true);
    if (bill) {
      LegislatorService.getLegislator(bill?.sponsor_id).then((legislator) =>
        setLegislator(legislator.results[0]),
      );

      setPhotoUrl(LegislatorService.getPortraitUrl(bill?.sponsor_id));
      setImgLoading(false);
    }
  }, [bill]);

  return (
    <>
      <Grid grow gutter={5}>
        <Grid.Col span={3}>
          {/* Portrait */}
          <Grid.Col span={2} className="p-2 max-w-[165px] min-w-[165px]">
            <Skeleton height={200} visible={imgLoading}>
              <img
                className="max-w-[100%] min-w-[0%]"
                src={photoUrl}
                alt={bill?.sponsor_name}
              />
            </Skeleton>
            {legislator && (
              <>
                <p>
                  {`${legislator.roles[0].short_title} ${legislator.first_name} ${legislator.last_name}`}
                </p>
                <p>{`(${legislator.roles[0].state}-${legislator.roles[0].party})`}</p>
                <p>Legislator: {bill?.sponsor_id}</p>
              </>
            )}
            {!legislator && (
              <>
                <Skeleton height={16} mt={5} radius="xl" />
                <Skeleton height={16} mt={5} width={"50%"} radius="xl" />
                <Skeleton height={16} mt={5} radius="xl" />
              </>
            )}
          </Grid.Col>
        </Grid.Col>

        <Grid.Col span={9}>
          {/* Title */}
          <Grid.Col
            span={9}
            className="align-middle mr-8 mb-4 mt-4 text-xl font-semibold w-2/3"
          >
            <div>
              {bill?.short_title}
              <div className="font-normal">{bill?.bill_id}</div>
            </div>
          </Grid.Col>

          <Grid.Col span={2}></Grid.Col>
          {/* Bill Text Area */}
          <Grid.Col span={8}>
            <ScrollArea h={250} className="justify-start">
              {billText}
            </ScrollArea>
          </Grid.Col>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default BillProfile;
