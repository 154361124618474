import { Bill } from "../shared/Bill";
import { Carousel } from "@mantine/carousel";
import BillCardTemplate from "./BillCardTemplate";

type Props = {
  bills: Bill[];
};

function BillCarousel({ bills }: Props) {
  return (
    <>
      <Carousel
        slideSize="100%"
        height="100%"
        slideGap="xs"
        controlsOffset="xs"
        align="start"
        controlSize={25}
      >
        {bills.map((bill, index) => {
          return (
            <Carousel.Slide key={index} size="416px">
              {BillCardTemplate(bill)}
            </Carousel.Slide>
          );
        })}
      </Carousel>
    </>
  );
}

export default BillCarousel;
