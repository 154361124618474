import BillCardTemplate from "../components/BillCardTemplate";
import BillCarousel from "../components/BillCarousel";
import { Bill } from "../shared/Bill";
import { Grid } from "@mantine/core";

function Sandbox() {
  const testBill: Bill = {
    active: false,
    bill_id: "sres65-118",
    bill_slug: "sres65",
    committees: "",
    committee_codes: [],
    congress: 118,
    sponsor_name: "Elissa Slotkin",
    sponsor_state: "MI",
    sponsor_party: "D",
    sponsor_id: "S001208",
    short_title:
      "A resolution opposing a national sales tax on working families and supporting a tax cut to benefit the middle class.",
  };

  return (
    <>
      <div>
        <Grid grow gutter={5}>
          <Grid.Col span={2} className="max-w-[165px] min-w-[165px]">
            <div className="border-2 border-black text-center h-[200px]">1</div>
          </Grid.Col>

          <Grid.Col span={8} className="max-h-[100%]">
            <div className="border-2 border-black text-center">2</div>
          </Grid.Col>

          <Grid.Col span={2}>
            <div className="border-2 border-black text-center">3</div>
          </Grid.Col>

          <Grid.Col span={8}>
            <div className="border-2 border-black text-center">4</div>
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
}

export default Sandbox;
