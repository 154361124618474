import { useState, useEffect } from "react";
import { Bill } from "../shared/Bill";
import BillService from "../services/BillService";
import { useSearchParams } from "react-router-dom";
import BillCardTemplate from "../components/BillCardTemplate";
import BillResultTemplate from "../components/BillResultTemplate";

function Bills() {
  const passedUrl =
    "https://api.propublica.org/congress/v1/116/both/bills/passed.json";

  const [bills, setBills] = useState<Bill[]>();
  const [searchTerm, setSearchTerm] = useState<string>();

  const [urlParams] = useSearchParams();

  useEffect(() => {
    setSearchTerm(urlParams.get("term") ?? "");
  }, []);

  useEffect(() => {
    if (searchTerm) {
      BillService.getBySearch(searchTerm).then((bills) => {
        setBills(bills);
      });
    }
  }, [searchTerm]);

  // useEffect(() => {
  //   fetch(passedUrl, {
  //     method: "GET",
  //     headers: {
  //       "X-API-Key": "3Lqy5xQtjuEg1VhIizxURREwfyWzmmei7dXOHBrF",
  //     },
  //   })
  //     .then((data) => {
  //       return data.json();
  //     })
  //     .then((bills) => {
  //       setBills(bills.results[0].bills);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, [bills]);

  return (
    <>
      <h1 className="text-5xl text-center">Bills</h1>
      <ul className="text-center">
        {bills?.map((bill) => (
          <BillResultTemplate bill={bill} />
        ))}
      </ul>
    </>
  );
}

export default Bills;
