import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Bills from "./pages/Bills";
import BillProfile from "./pages/BillProfile";
import Sandbox from "./pages/Sandbox";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Parent */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="bills" element={<Bills />} />
            <Route path="billprofile/:id" element={<BillProfile />} />
            <Route path="sandbox" element={<Sandbox />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
