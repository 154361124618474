import { Link } from "react-router-dom";
import { Bill } from "../shared/Bill";
import LegislatorPortraitService from "../services/LegislatorService";
import "./BillCardTemplate.css";

const BillCardTemplate = (bill: Bill) => {
  return (
    <>
      <Link to={`/BillProfile/${bill.bill_id}`}>
        <div className="flex flex-row-reverse shrink justify-end border-[1px] border-solid border-black rounded-2xl m-2 text-center w-[416px] h-[290px]">
          <div className="flex flex-col pl-2 w-1/2">
            <p className="text-left font-mate font-semibold">{bill.bill_id}</p>
            <p className="font-mate text-left tracking-tight h-2/3 fade">
              {bill.short_title}
            </p>
            <p id="test" className="align-bottom mt-auto">
              Sponsor: {bill.sponsor_name}
            </p>
          </div>
          <img
            alt={bill.sponsor_name}
            src={LegislatorPortraitService.getPortraitUrl(bill.sponsor_id)}
            className="rounded-l-xl"
          />
        </div>
      </Link>
    </>
  );
};

export default BillCardTemplate;
