const LegislatorService = {
  // Return portrait url from bioguide
  getPortraitUrl: (legislatorId: string) => {
    return `https://bioguide.congress.gov/bioguide/photo/${legislatorId.substring(
      0,
      1,
    )}/${legislatorId}.jpg`;
  },
  // Return legislator data object
  getLegislator: (legislatorId: string) => {
    return fetch(
      `https://api.propublica.org/congress/v1/members/${legislatorId}.json`,
      {
        method: "GET",
        headers: {
          "X-API-Key": "3Lqy5xQtjuEg1VhIizxURREwfyWzmmei7dXOHBrF",
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export default LegislatorService;
