const BillService = {
  getRecentlyPassed: () => {
    return fetch(
      `https://api.propublica.org/congress/v1/118/both/bills/passed.json`,
      {
        method: "GET",
        headers: {
          "X-API-Key": "3Lqy5xQtjuEg1VhIizxURREwfyWzmmei7dXOHBrF",
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.results[0].bills;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getUpcoming: () => {
    return fetch(
      `https://api.propublica.org/congress/v1/bills/upcoming/senate.json`,
      {
        method: "GET",
        headers: {
          "X-API-Key": "3Lqy5xQtjuEg1VhIizxURREwfyWzmmei7dXOHBrF",
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.results[0].bills;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getBySearch: (term: string) => {
    return fetch(
      `https://api.propublica.org/congress/v1/bills/search.json?query=${term}`,
      {
        method: "GET",
        headers: {
          "X-API-Key": "3Lqy5xQtjuEg1VhIizxURREwfyWzmmei7dXOHBrF",
        },
      },
    )
      .then((response) => response.json())
      .then((data) => data.results[0].bills)
      .catch((error) => {
        return console.error(error);
      });
  },
  getBill: (id: string) => {
    return fetch(
      `https://voturapi.azurewebsites.net/api/bills/${id}?code=4ZjlUw6tIi84CM6bYww5yqEWhJSRJGsWnKw2BLKVsh9mAzFuoILk3g==`,
    )
      .then((response) => response.json())
      .then((bill) => bill)
      .catch((error) => {
        console.error(error);
      });
  },
  getBillText: (id: string): Promise<string> => {
    //ex. https://www.congress.gov/117/bills/s5066/BILLS-117s5066enr.htm&
    // Generate URL
    const congress = id.substring(id.length - 3);
    const [billType, billNumber] = billParts(id) ?? [null, null];
    const text_url = `https://api.congress.gov/v3/bill/${congress}/${billType}/${billNumber}/text?api_key=FG6UbXfWbnqZo82HvlNo9LYsrJr5YG1gxHvL7QGl`;

    // Retrieve URL response
    return fetch(text_url)
      .then((response) => response.json())
      .then((data) => {
        let formats = data.textVersions[0].formats;
        if (data.textVersions[0].formats.length > 0) {
          // Get the most recent text of the bill
          return fetch(
            `https://voturapi.azurewebsites.net/api/WebProxy?url=${data.textVersions[0].formats[0].url}&code=n1fLJmlq_rXGBMB4y8dehMitscd_LyCqYjoNOefwJJYeAzFuhg-WQg==`,
          )
            .then((response) => response.json())
            .then((text) => text.Content)
            .catch((error) => console.error(error));
        } else {
          return new Error(`No text versions found for ${id}`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

function billParts(billId: string): [string, number] | undefined {
  const regex = new RegExp(
    "(hr|s|hjres|sjres|hconres|sconres|hres|sres)(\\d+)",
  );
  var matches = regex.exec(billId);
  if (matches !== null) {
    return [matches[1], parseInt(matches[2])];
  }
}

export default BillService;
