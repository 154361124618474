import { Link } from "react-router-dom";
import { Bill } from "../shared/Bill";
import LegislatorPortraitService from "../services/LegislatorService";

type Props = {
  bill: Bill;
};

function BillResultTemplate({ bill }: Props) {
  return (
    <Link to={`/BillProfile/${bill.bill_id}`}>
      {bill.title}
      <br />
      {bill.bill_slug}
      <br />
      {bill.sponsor_name}
      <br />
    </Link>
  );
}

export default BillResultTemplate;
