import BillCarousel from "../components/BillCarousel";
import BillService from "../services/BillService";
import { useEffect, useState } from "react";
import { Bill } from "../shared/Bill";

const Home = () => {
  // Get array of recently passed bills
  const [recentBills, setRecentBills] = useState<Bill[]>([]);
  const [upcomingBills, setUpcomingBills] = useState<Bill[]>([]);
  const [searchTermBills, setSearchTermBills] = useState<Bill[]>([]);

  // Get bills to populate carousels
  useEffect(() => {
    BillService.getRecentlyPassed().then((list) => setRecentBills(list));
    BillService.getUpcoming().then((list) => setUpcomingBills(list));
    BillService.getBySearch("cannabis").then((list) =>
      setSearchTermBills(list),
    );
  }, []);

  return (
    <div className="ml-6">
      {recentBills && recentBills.length > 0 && (
        <section>
          <p className="ml-4 mb-1 mt-4 text-xl font-semibold">
            Recently Passed Bills
          </p>
          <BillCarousel bills={recentBills} />
        </section>
      )}

      {upcomingBills && upcomingBills.length > 0 && (
        <section>
          <p className="ml-4 mb-1 mt-4 text-xl font-semibold">Upcoming Bills</p>
          <BillCarousel bills={upcomingBills} />
        </section>
      )}

      {searchTermBills && searchTermBills.length > 0 && (
        <section>
          <p className="ml-4 mb-1 mt-4 text-xl font-semibold">Cannabis</p>
          <BillCarousel bills={searchTermBills} />
        </section>
      )}
    </div>
  );
};

export default Home;
